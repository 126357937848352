import revive_payload_client_A3WlzN4mug from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_U3UlH4tZIM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IHCUngmzJf from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MxW5YaLvH0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XeZY97jpZc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xNyLcZ7E5w from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GYL99UZepw from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_wFYYdmYay1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_X0Ourw2CUb from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.2_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._bam3w57nqaj7gqlbqoe5kcg4eq/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_A3WlzN4mug,
  unhead_U3UlH4tZIM,
  router_IHCUngmzJf,
  payload_client_MxW5YaLvH0,
  navigation_repaint_client_XeZY97jpZc,
  check_outdated_build_client_xNyLcZ7E5w,
  chunk_reload_client_GYL99UZepw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wFYYdmYay1,
  plugin_ywJDomcNte,
  tooltip_X0Ourw2CUb,
  bugsnag_tkMQfOjcVZ
]