import type { Auth2 } from '~~/types/gapi'
import type { CountryConfiguration, User } from '~~/types'

let singletonInterval: NodeJS.Timeout
// a modest 22sec timer to check token expiry
const expiryTicker = 22000

export default function () {
  const config = useRuntimeConfig()
  const user = useState<User>('user')
  const token = useSessionStorage<string>('api_token', null)
  const tokenExpiresAt = useSessionStorage<number>('api_token_expires_at', null)
  const countryConfig = useState<CountryConfiguration | undefined>('countryConfig')

  const checkExpiry = () => {
    if (tokenExpiresAt.value < Date.now())
      logout()
  }

  // do not let composable invocations reassign the interval
  singletonInterval ??= setInterval(checkExpiry, expiryTicker)

  const initGoogle = (): Promise<Auth2> => new Promise((resolve) => {
    if (window.gapi.auth2)
      return resolve(window.gapi.auth2.getAuthInstance())

    window.gapi.load('auth2', () => {
      resolve(window.gapi.auth2.init({
        client_id: config.public.googleClientId,
        hosted_domain: 'docue.com',
      }))
    })
  })

  const fetchInitialData = async () => {
    user.value ??= await useApi().me().then(r => r.data)
    countryConfig.value ??= await useApi().fetchCountryConfiguration().then(r => r.data)
  }

  const login = async () => {
    try {
      const googleUser = await initGoogle().then(auth2 => auth2.signIn())
      token.value = await useApi().auth(googleUser.getAuthResponse().id_token).then(r => r.accessToken)
      tokenExpiresAt.value = JSON.parse(decodeURIComponent(window.atob(token.value.split('.')[1]))).exp * 1000
      // Wait for nextTick so the token will be persisted and the api can see it
      await nextTick()
      await fetchInitialData()
      // reset the expiry ticker
      clearInterval(singletonInterval)
      singletonInterval = setInterval(checkExpiry, expiryTicker)
    }
    catch (er) {
      console.warn(er)
      useFlashNotifications().show({ message: 'Login failed', status: 'warning', ms: 10000 })
    }
  }

  // eslint-disable-next-line func-style
  async function logout() {
    await initGoogle().then(auth2 => auth2.signOut())
    await useApi().logout()
    token.value = null
    clearInterval(singletonInterval)
    navigateTo('/login')
  }

  return {
    token,
    tokenExpiresAt,
    user,
    fetchInitialData,
    countryConfig,
    login,
    logout,
  }
}
